import React from "react";
import "./notification.css";

// icons
import { TbFaceIdError, TbSquareRoundedCheckFilled } from "react-icons/tb";

function Notification({ state, error, msg }) {
  return (
    <>
      {error === true ? (
        <div className={"Notification error " + state}>
          <div className="container">
            <TbFaceIdError className="icon" />
            Une erreur est survenue {msg}
          </div>
        </div>
      ) : (
        <div className={"Notification " + state}>
          <div className="container">
            <TbSquareRoundedCheckFilled className="icon" />
            L'opération est un succés
          </div>
        </div>
      )}
    </>
  );
}

export default Notification;
