import React from "react";
import "./navBar.css";

// icons
import { SlArrowLeft } from "react-icons/sl";
import { Link } from "react-router-dom";

function NavBar({ link, title }) {
  return (
    <div className="NavBar">
      <Link to={link}>
        <SlArrowLeft className="icon" />
        <div className="title">{title}</div>
      </Link>
    </div>
  );
}

export default NavBar;
