import React, { useState } from "react";
import "./uploadQuote.css";
import Notification from "../../components/notification";
import NavBar from "../../components/bar/navBar";
import { app } from "../../components/firebase/firebase";
import AudioSource from "../../assets/sound/check.mp3";
import AudioFailed from "../../assets/sound/failed.mp3";
import ButtonComponent from "../../components/button";

// icons
import { BsChatQuote } from "react-icons/bs";
import { HiFingerPrint } from "react-icons/hi";

function UploadQuote() {
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [notification, setNotification] = useState(false);
  const [button, setButton] = useState("none");

  const [index, setIndex] = useState("");
  const [quote, setQuote] = useState("");

  const db = app.firestore();

  const handleError = (error) => {
    setButton("none");
    setError(true);
    setErrorMsg(error);
    setNotification(true);
    const audio = new Audio(AudioFailed); // Remplacez le chemin par le chemin vers votre fichier audio
    audio.play();
    setTimeout(() => {
      setNotification(false);
    }, 3000);
  };

  const handleSubmit = () => {
    setButton("loading");
    if (index.length > 4 && quote.length > 0) {
      db.collection("image")
        .doc(index)
        .get()
        .then((data) => {
          if (data.data() !== undefined) {
            db.collection("quote")
              .doc(index)
              .set({
                date: Date.now(),
                index: parseInt(index),
                indexString: index,
                quote,
              })
              .then(() => {
                db.collection("info")
                  .doc("quote")
                  .get()
                  .then((data) => {
                    db.collection("info")
                      .doc("quote")
                      .update({ number: data.data().number + 1 })
                      .then(() => {
                        setButton("none");
                        setError(false);
                        setNotification(true);
                        const audio = new Audio(AudioSource); // Remplacez le chemin par le chemin vers votre fichier audio
                        audio.play();
                        setTimeout(() => {
                          setNotification(false);
                        }, 3000);
                      });
                  });
              })
              .catch(() => handleError("#455"));
          } else {
            handleError("#455");
          }
        })
        .catch(() => handleError("#455"));
    } else {
      handleError("#455");
    }
  };

  return (
    <>
      <NavBar link="/" title="Administration" />
      <Notification state={notification} error={error} msg={errorMsg} />
      <div className="UploadQuote">
        <div className="header">
          <div className="title text-6xl">Citation de l'image</div>
        </div>
        <div className="wrapper">
          <div className="form">
            <label htmlFor="index" className="ctn-input" id="ctnIndex">
              <HiFingerPrint className="icon" />
              <input
                type="text"
                id="index"
                placeholder="ID de l'image"
                onChange={(e) => setIndex(e.target.value)}
                value={index}
                onFocus={() =>
                  document.getElementById("ctnIndex").classList.toggle("active")
                }
                onBlur={() =>
                  document.getElementById("ctnIndex").classList.toggle("active")
                }
              />
            </label>
            <label htmlFor="quote" className="ctn-input" id="ctnQuote">
              <BsChatQuote className="icon" />
              <input
                type="text"
                id="quote"
                placeholder="Citation de l'image"
                onChange={(e) => setQuote(e.target.value)}
                value={quote}
                onFocus={() =>
                  document.getElementById("ctnQuote").classList.toggle("active")
                }
                onBlur={() =>
                  document.getElementById("ctnQuote").classList.toggle("active")
                }
              />
            </label>
          </div>
          <div className="ctn-button">
            <ButtonComponent
              text="Ajouter"
              onClick={() => handleSubmit()}
              state={button}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default UploadQuote;
