import React, { useEffect, useState } from "react";
import "./manageQuote.css";
import "../manageImage/manageImage.css";
import NavBar from "../../components/bar/navBar";
import { app } from "../../components/firebase/firebase";
import Notification from "../../components/notification";
import AudioSource from "../../assets/sound/check.mp3";

// icons
import { RiSearchLine } from "react-icons/ri";
import ButtonComponent from "../../components/button";

function ManageQuote() {
  const [images, setImages] = useState([]);
  const [current, setCurrent] = useState(1);
  const [number, setNumber] = useState(0);

  const [loading, setLoading] = useState("none");
  const [btnDelete, setBtnDelete] = useState("disabled");

  const [selected, setSelected] = useState([]);

  const [notification, setNotification] = useState(false);

  const [change, setChange] = useState(1);

  const db = app.firestore();

  function formatDate(timestamp) {
    const date = new Date(timestamp);

    // Récupérer le jour, le mois et l'année
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Les mois sont indexés à partir de 0
    const year = date.getFullYear();

    // Concaténer les éléments pour former la date au format "jj/mm/aaaa"
    const formattedDate = `${day}/${month}/${year}`;

    return formattedDate;
  }

  useEffect(() => {
    db.collection("info")
      .doc("quote")
      .get()
      .then(async (data) => {
        setNumber(data.data().number);
        const querySnapshot = await db
          .collection("quote")
          .orderBy("index") // Tri par ordre croissant de la propriété "index"
          .startAt(10000 + parseInt(data.data().number) - 40) // Commencez à l'index du dernier élément de la page actuelle - 40
          .limit(40)
          .get();

        const imagesData = querySnapshot.docs.map((doc) => doc.data()); // Extraction des données des documents

        // Triez le tableau d'imagesData dans l'ordre décroissant de la propriété "index"
        imagesData.sort((a, b) => b.index - a.index);

        setImages(imagesData); // Mettez à jour le state avec le tableau trié des images
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération des données:", error);
      });
  }, [change]);

  const handleClick = async () => {
    setLoading("loading");
    if (images.length < number) {
      // Utilisez "less than" (<) au lieu de "not equal" (!=)
      const querySnapshot = await db
        .collection("quote")
        .orderBy("index") // Tri par ordre croissant de la propriété "index"
        .startAt(10000 + parseInt(number) - 40 * current) // Commencez à l'index du dernier élément de la page actuelle - 40
        .limit(40)
        .get();

      const imagesData = querySnapshot.docs.map((doc) => doc.data()); // Extraction des données des documents

      // Triez le tableau d'imagesData dans l'ordre décroissant de la propriété "index"
      imagesData.sort((a, b) => b.index - a.index);

      setImages([...images, ...imagesData]); // Utilisez le spread operator (...) pour fusionner les tableaux
      setCurrent(current + 1);
      setLoading("none");
    } else {
      setLoading("none");
    }
  };

  const handleSelect = (id, event) => {
    console.log(event);
    const isSelected = selected.includes(id);
    let temp;

    if (isSelected) {
      temp = selected.filter((number) => number !== id);
    } else {
      temp = [...selected, id];
    }
    setSelected(temp);
    setBtnDelete(temp.length > 0 ? "none" : "disabled");
    const target = event.currentTarget;
    target.classList.toggle("active");
  };

  const handleDelete = () => {
    setBtnDelete("loading");
    var lenght = selected.length;
    var temp = images;
    selected.forEach(async (id, index) => {
      try {
        // Supprimer le document de la collection "image" dans Firestore
        await db.collection("quote").doc(id.toString()).delete();
        await db
          .collection("info")
          .doc("quote")
          .update({ number: number - (index + 1) });

        document.getElementById(id).className = "";
        temp = temp.filter((item) => item.index !== id);
        if (index === lenght - 1) {
          setBtnDelete("disabled");
          setSelected([]);
          setImages(temp);
          setNotification(true);
          const audio = new Audio(AudioSource); // Remplacez le chemin par le chemin vers votre fichier audio
          audio.play();
          setTimeout(() => {
            setNotification(false);
          }, 3000);
        }
      } catch (error) {
        console.error(
          `Erreur lors de la suppression du document ou du fichier avec l'ID ${id}:`,
          error
        );
        setBtnDelete("disabled");
      }
    });
  };

  const handleSearch = (value) => {
    clearAllSelections();
    if (value.length >= 3) {
      // Comptez le nombre de chiffres dans la chaîne de recherche
      const searchDigits = value.length;

      // Calcul de la valeur maximale de recherche en ajoutant 1 au dernier chiffre
      const maxValue = Number(value + "9".repeat(10 - searchDigits));

      // Effectuer la recherche dans la collection "maCollection" avec le champ "index" commençant par 104
      db.collection("quote")
        .where("indexString", ">=", value)
        .where("indexString", "<=", maxValue.toString()) // Convertissez maxValue en chaîne de caractères pour la comparaison
        .get()
        .then((querySnapshot) => {
          const matchingDocuments = querySnapshot.docs.map((doc) => doc.data());
          matchingDocuments.sort((a, b) => b.index - a.index);
          setSelected([]);
          setBtnDelete("disabled");
          setImages(matchingDocuments);
        })
        .catch((error) => {
          console.error(
            "Erreur lors de la récupération des documents :",
            error
          );
        });
    } else {
      setChange(change + 1);
    }
  };
  const clearAllSelections = () => {
    for (const item of selected) {
      document.getElementById(item).className = "";
    }
  };

  const handleChange = (value, id) => {
    db.collection("quote")
      .doc(id.toString())
      .update({
        quote: value,
      })
      .then(() => {
        setNotification(true);
        const audio = new Audio(AudioSource); // Remplacez le chemin par le chemin vers votre fichier audio
        audio.play();
        setTimeout(() => {
          setNotification(false);
        }, 3000);
      });
  };

  const handleKeyUp = (event, value, id, tag) => {
    if (event.key === "Enter") {
      handleChange(value, id, tag);
    }
  };

  return (
    <>
      <NavBar link="/" title="Administration" />
      <Notification state={notification} />
      <div className="ManageQuote ManageImage">
        <div className="header">
          <div className="title text-6xl">Gérer les citations</div>
        </div>
        <div className="wrapper">
          <label htmlFor="search" className="ctn-search">
            <RiSearchLine className="icon" />
            <input
              type="search"
              name=""
              id="search"
              onChange={(e) => handleSearch(e.target.value)}
              placeholder="Rechercher par numéro d'image"
            />
          </label>
          <div className="result">
            <div className="top">
              <div className="left">{images.length} citations</div>
              <div className="right">
                <ButtonComponent
                  text="Supprimer"
                  state={btnDelete}
                  color="red"
                  onClick={() => handleDelete()}
                />
              </div>
            </div>
            <div className="table">
              <table>
                <thead>
                  <td></td>
                  <td>ID</td>
                  <td>Date</td>
                  <td>Tags</td>
                </thead>
                <tbody>
                  {images.map((data, index) => (
                    <tr
                      id={data.index}
                      onClick={(event) =>
                        handleSelect(parseInt(data.index), event)
                      }
                      key={index}
                    >
                      <td>
                        <div className="ctn-input">
                          <div className="check"></div>
                        </div>
                      </td>
                      <td>@CC{data.index}</td>
                      <td>{formatDate(data.date)}</td>
                      <td>
                        <input
                          onKeyUp={(e) =>
                            handleKeyUp(e, e.target.value, data.index, 3)
                          }
                          onClick={(event) => event.stopPropagation()}
                          placeholder="Tag 4"
                          defaultValue={data.quote}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="ctn-button">
              <ButtonComponent
                text="Charger plus"
                onClick={() => handleClick()}
                state={loading}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ManageQuote;
