import React, { useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

// css
import "./App.css";
import "./assets/css/reset.css";
import "./assets/css/root.css";

// components
import Home from "./pages/home/index.jsx";
import UploadImage from "./pages/uploadImage";
import ManageImage from "./pages/manageImage";
import UploadQuote from "./pages/uploadQuote";
import ManageQuote from "./pages/manageQuote/manageQuote";

function App() {
  const [loading, setLoading] = useState(true);
  const [isVerified, setIsVerified] = useState(false);
  const [password, setPassword] = useState("");

  const handleRecaptchaChange = (value) => {
    setIsVerified(value ? true : false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isVerified) {
      const db = firebase.firestore();
      const docRef = db.collection("login").doc("loginDocument");
      const doc = await docRef.get();
      const correctPassword = doc.data().password;

      if (password === correctPassword) {
        setLoading(false);
      } else {
        resetRecaptcha();
      }
    }
  };
  const resetRecaptcha = () => {
    const recaptchaRef = React.createRef();
    recaptchaRef.current.reset();
  };

  // Call the resetRecaptcha function whenever you want to reset the ReCAPTCHA

  window.recaptchaOptions = {
    useRecaptchaNet: true,
    enterprise: true,
  };

  const router = createBrowserRouter([
    {
      // errorElement: <ErrorPage />,
      path: "/",
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/upload-image",
          element: <UploadImage />,
        },
        {
          path: "/manage-image",
          element: <ManageImage />,
        },
        {
          path: "/upload-quote",
          element: <UploadQuote />,
        },
        {
          path: "/manage-quote",
          element: <ManageQuote />,
        },
      ],
    },
  ]);
  return (
    <div className="App">
      {loading ? (
        // Affiche le loader pendant le chargement des données
        <>
          <div className="Login">
            <h1>Login</h1>
            <form onSubmit={handleSubmit}>
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <ReCAPTCHA
                sitekey="6LeCPDYaAAAAAGAgp95WWl42a-ycy1vBCANv0_sE"
                onChange={handleRecaptchaChange}
              />
              <button type="submit">Login</button>
            </form>
          </div>
        </>
      ) : (
        // Affiche le contenu de l'application une fois les données chargées
        <RouterProvider router={router} />
      )}
    </div>
  );
}

export default App;
